import simpleParallax from 'simple-parallax-js';

$( document ).ready(function() {

    /* Slider */
    $( ".show-details, .close-person-slide-in, .meta-slide-in-bg" ).click(function(e) {
        e.preventDefault();

        var image = $(e.target).parents('.person').find('.person-popup-image').attr('src');
        var name = $(e.target).parents('.person').parent().find('.person-name').text();
        var position = $(e.target).parents('.person').parent().find('.person-position').text();
        var quote = $(e.target).parents('.person').parent().find('.person-quote').text();
        var description = $(e.target).parents('.person').parent().find('.person-description').text();
        var linkedin = $(e.target).parents('.person').parent().find('.person-linkedin').text();
        var email = $(e.target).parents('.person').parent().find('.person-email').text();
        var phone = $(e.target).parents('.person').parent().find('.person-phone').text();

        $('.slide-in-image-container').css('background-image', 'url(' + image + ')');
        $('.slide-in-name').text( name );
        $('.slide-in-position').text( position );
        $('.slide-in-quote').text( quote );
        $('.slide-in-description').text( description );
        $('.slide-in-linkedin').text( linkedin );
        $('.slide-in-email').text( email );
        $('.slide-in-phone').text( phone );

        $('.meta-slide-in, .meta-slide-in-bg').toggleClass('show');
    });

    /* Parallax */
    var hero = document.getElementsByClassName('grain-pattern');
    new simpleParallax(hero, {
        scale: 1.25
    });

    var balls = document.getElementsByClassName('ball');
    new simpleParallax(balls, {
        scale: 1.4
    });

    var grainBlock = document.getElementsByClassName('grain-block');
    new simpleParallax(grainBlock, {
        scale: 1.25
    });

    var antiBodies = document.getElementsByClassName('antibody');
    new simpleParallax(antiBodies, {
        scale: 1.5 // svgs inside the antibody div get a transform: scale(0.5) style to prevent them from becoming huge
    });
});