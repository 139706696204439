import '../scss/index.scss';
import jQuery from 'jquery';
import smoothscroll from 'smoothscroll-polyfill';
import './lib/ScreenDetector'; // initializes screensize etc.
import FormValidator from './lib/FormValidator.js';
import Modals from './lib/Modals.js';


// Import from lib
import './lib/main';
import './lib/collapsible';
import Utils from "./lib/Utils";
import InfiniteScroll from "infinite-scroll-init";

// import js for specific pages
import PageHome from './pages/Home.js';
import PagePressRelease from './pages/PressRelease.js';
import PagePressReleases from './pages/PressReleases.js';
// import './pages/contact';

const page = $('body').data('page-key');
switch (page) {
    case 'home':
        new PageHome();
        break;
    case 'press-release':
        new PagePressRelease();
        break;
    case 'press-releases':
        new PagePressReleases();
        break;
}

function setOnScroll(cb) {
    // Reference: http://www.html5rocks.com/en/tutorials/speed/animations/

    let last_known_scroll_position = 0;
    let ticking = false;

    window.addEventListener('scroll', e => {
        last_known_scroll_position = window.scrollY;

        if (!ticking) {
            window.requestAnimationFrame(() => {
                cb(last_known_scroll_position);
                ticking = false;
            });
            ticking = true;
        }
    });
}

$(document).ready(() => {
    const navMenuToggle = document.querySelector('.nav-menu-toggle');
    const topbar = document.querySelector('#topbar');

    window.scrollBy(0, -1); // scroll one pixel up on page load to trigger menu styling if user has scrolled before page refresh

    let scrollExceedsHeight = false;
    setOnScroll((pos) => {
        if (pos > topbar.getBoundingClientRect().height) {
            if (scrollExceedsHeight === false) {
                scrollExceedsHeight = true;
                topbar.classList.add('alternate');
            }
        } else {
            if (scrollExceedsHeight === true && navMenuToggle.checked == false) {
                scrollExceedsHeight = false;
                topbar.classList.remove('alternate');
            }
        }
    })

    smoothscroll.polyfill();

    //Modals.initModals(); initializes modals from existing HTML
    new FormValidator().processForms();

    // A class needs to be added to topbar in order to get a white background when mobile menu is click while scroll is at top of page
    navMenuToggle.addEventListener('click', event => {
        if (scrollExceedsHeight === false) {
            if (navMenuToggle.checked == true) {
                topbar.classList.add('alternate');
            } else {
                topbar.classList.remove('alternate');
            }
        }
    });
});