import Utils from "../lib/Utils";
import InfiniteScroll from "infinite-scroll-init";

export default class PagePressReleases {
    constructor() {
        Utils.distributeDecorations();

        // document.getElementById('load-more').addEventListener('click', (e) => {
        //     e.currentTarget.style.display = 'none';
            new InfiniteScroll({
                container: '#content-container',
                autoFill: true,
                autoScroll: true,
                offset: document.documentElement.clientHeight,
                dataRoute: '/news/data',
                dataType: 'html',
                onSuccess: (res) => {
                    const container = document.getElementById("content-container");
                    container.insertAdjacentHTML('beforeend', res);
                },
                loadingIndicator: {
                    active: true,
                    type: 0,
                    html: `
                    <div class="inf-loading-indicator">
                        <div class="dot-flashing"></div>
                    <div>`,
                }
            });
       // });
    }
}