export default class Utils {
    static distributeDecorations() { 
        document.querySelectorAll('.aside-decoration').forEach((el) => {
            let key = Array.from(el.parentNode.children).indexOf(el);
            let minPos = (el.parentElement.clientHeight / el.parentNode.children.length) * key;
            let maxPos = (el.parentElement.clientHeight / el.parentNode.children.length) * (key+1) - el.querySelector('.img').clientHeight;
            // console.log(el.querySelector('.img').clientHeight);
            // console.log(minPos);
            // console.log(maxPos);

            if ( el.dataset.showFrom <= maxPos ) {
                el.classList.remove('hidden');
            }

            el.style.height = el.querySelector('.img').clientHeight+"px";
            el.style.marginTop = 'calc('+ (Math.floor(Math.random() * (maxPos - minPos) + minPos) ) + 'px + 58rem)';
        });
    }
}