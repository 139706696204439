$(document).ready(() => {

    document.querySelectorAll('[data-toggle="collapse"]').forEach(clickCollapseEventHandler);

    function clickCollapseEventHandler(el) {
        el.addEventListener('click', (ev) => {
            let elm = ev.target;
            while (!elm.getAttribute('data-toggle')) {
                elm = elm.parentElement;
            }
            elm.classList.toggle('active');
            if (elm.classList.contains('active')) {
                elm.querySelectorAll('[data-src-active]').forEach((element) => {
                    element.src = element.dataset.srcActive;
                });
            } else {
                elm.querySelectorAll('[data-src-active]').forEach((element) => {
                    element.src = element.dataset.srcInactive;
                });
            }
            const selector = elm.getAttribute('data-target');
            collapse(selector);
        }, false);
    }

    const collapse = (selector) => {
        const elm = document.querySelectorAll(selector)[0];
        const targets = Array.from(document.querySelectorAll('.collapse'));
        targets.forEach(target => {
            if (target == elm) {
                target.classList.toggle('show');
            } else {
                //target.classList.remove('show');
            }
        });
    }

    if (document.querySelector(".collapsible")) {
        document.querySelector(".collapsible").addEventListener('resetTriggers', (event) => {
            event.target.querySelectorAll('[data-toggle="collapse"]').forEach(clickCollapseEventHandler);
        });
    }
});