const breakpoints = {
    xs: 20 * 16,      // 320px
    sm: 43 * 16,      // 688px
    md: 62 * 16,      // 992px
    lg: 82 * 16,      // 1312px
    xlg: 102 * 16,    // 1632px
    xxl: 120 * 16     // 1920px
  };
  
  
  module.exports = {
    "breakpoints": breakpoints
  }